/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex"
import { IUserState } from "@/store/main/user/types"
import { IRootState } from "@/store/types"
import { sendPost } from "@/service/main/system/system"

const orderUserRefModule: Module<IUserState, IRootState> = {
  namespaced: true,
  state() {
    return {}
  },
  getters: {},
  mutations: {},
  actions: {
    async auditAction(_, payload: any) {
      const pageResult = await sendPost(`orderUserRef/audit`, payload.row)

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    },
    async settleAction(_, payload: any) {
      const pageResult = await sendPost(`orderUserRef/settle`, payload.row)

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    }
  }
}

export default orderUserRefModule
