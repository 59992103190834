/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRootState } from "@/store/types"
import { Module } from "vuex"
import { sendPost } from "@/service/main/system/system"
import { IBillMissionState } from "./types"

const billMissionModule: Module<IBillMissionState, IRootState> = {
  namespaced: true,
  state() {
    return {
      noPayCount: 0
    }
  },
  mutations: {
    setNoPayCount(state, params) {
      localStorage.setItem("noPayCount", params)
      state.noPayCount = params
    },
    getCacheNoPayCount(state) {
      const cacheData = Number(localStorage.getItem("noPayCount"))
      if (cacheData) {
        state.noPayCount = cacheData
      }
    }
  },
  actions: {
    async transfer(_, payload: any) {
      const pageResult = await sendPost(`billMission/transfer`, {
        id: payload.id,
        bmType: payload.bmType
      })

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    },
    async discard(_, payload: any) {
      const pageResult = await sendPost(`billMission/discard`, {
        id: payload.id,
        remark: payload.remark
      })

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    },
    async getNoPayCount({ commit }) {
      const pageResult = await sendPost("billMission/getNoPayCount", {})
      const result = pageResult.data ?? 0
      commit("setNoPayCount", result)
    },
    async getCacheNoPayCount({ commit }) {
      commit("getCacheNoPayCount")
    }
  }
}

export default billMissionModule
