/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex"
import { ILoginState } from "@/store/login/types"
import { IRootState } from "@/store/types"
import {
  accountLoginRequest,
  requestUserInfoByName,
  requestUserMenus,
  requestServiceScopes
} from "@/service/login/login"
import localCache from "@/utils/cache"
import router from "@/router"
import { mapMenusToRoutes } from "@/utils/map-menus"

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true, //
  state() {
    return {
      token: "",
      userInfo: {},
      userMenus: [],
      serviceScopes: []
    }
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus

      // userMenus => routers
      const routes = mapMenusToRoutes(userMenus)
      // 将router => router.main.children
      routes.forEach((route) => {
        router.addRoute("main", route)
      })
    },
    changeServiceScopes(state, serviceScopes: any) {
      state.serviceScopes = serviceScopes
    }
  },
  actions: {
    async accountLoginAction(context, payload: any) {
      //实现登录逻辑
      const loginRes = await accountLoginRequest(payload)
      const { token } = loginRes.data

      if (token) {
        context.commit("changeToken", token)
        localCache.setCache("token", token)
        await context.dispatch("getInitialDataAction", null, { root: true })
      }

      //请求用户信息
      const userInfoRes = await requestUserInfoByName(payload)
      const userInfo = userInfoRes.data.user
      if (userInfo) {
        context.commit("changeUserInfo", userInfo)
        localCache.setCache("userInfo", userInfo)
      }

      //请求用户菜单
      const menusRes = await requestUserMenus(userInfo.id)
      const userMenus = menusRes.data
      if (userMenus) {
        context.commit("changeUserMenus", userMenus)
        localCache.setCache("userMenus", userMenus)
      }

      //请求服务范围
      const serviceScopesRes = await requestServiceScopes()
      const serviceScopes = serviceScopesRes.data
      if (serviceScopes) {
        const scopeArr = []
        for (const scope of serviceScopes) {
          scopeArr.push(scope.tagName)
        }
        context.commit("changeServiceScopes", scopeArr)
        localCache.setCache("serviceScopes", scopeArr)
      }

      //跳转到首页
      router.push("/main")
    },
    loadLocalLogin({ commit, dispatch }) {
      const token = localCache.getCache("token")
      if (token) {
        commit("changeToken", token)
        dispatch("getInitialDataAction", null, { root: true })
      }
      const userInfo = localCache.getCache("userInfo")
      if (userInfo) {
        commit("changeUserInfo", userInfo)
      }
      const userMenus = localCache.getCache("userMenus")
      if (userMenus) {
        commit("changeUserMenus", userMenus)
      }
    }
  }
}

export default loginModule
