// const BASE_URL = "http://coderwhy.org/dev"
// const BASE_NAME = "coderwhy"

// const BASE_URL = "http://coderwhy.org/prod"
// const BASE_NAME = "kobe"
//
// const BASE_URL = "http://coderwhy.org/test"
// const BASE_NAME = "james"

// process.env.NODE_ENV
//开发环境： development
//生产环境： production
//测试环境： test
let BASE_URL = ""
let BASE_NAME = ""
const TIME_OUT = 10000;

if(process.env.NODE_ENV === 'development'){
  BASE_URL = "/api"
  BASE_NAME = "coderwhy"
}else if(process.env.NODE_ENV === 'production'){
  BASE_URL = "/prod"
  BASE_NAME = "kobe"
}else if(process.env.NODE_ENV === 'test'){
  BASE_URL = "http://coderwhy.org/test"
  BASE_NAME = "james"
}

export {BASE_URL, BASE_NAME, TIME_OUT}
