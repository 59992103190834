/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"
import type { AxiosInstance } from "axios"
import { HYRequestConfig, HYRequestInterceptors } from "@/service/request/type"
import { ElLoading } from "element-plus/lib"
import { LoadingInstance } from "element-plus/lib/components/loading/src/loading"
import { IResponseType } from "@/service/types"
import localCache from "@/utils/cache"
import { ElMessage } from "element-plus/lib/index"

const DEAFULT_LOADING = true
//适配导出多个对象的差异
class HYRequest {
  instance: AxiosInstance
  interceptors?: HYRequestInterceptors
  showLoading: boolean
  loading?: LoadingInstance

  constructor(config: HYRequestConfig) {
    this.instance = axios.create(config)
    this.showLoading = config.showLoading ?? DEAFULT_LOADING
    this.interceptors = config.interceptors
    //使用拦截器
    // 1，从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor as any, //类型不兼容， 这里转为类型any
      this.interceptors?.requestInterceptorCatch
    )

    //2. 添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: "正在加载数据...",
            background: "rgba(0, 0 ,0, 0.5)"
          })
        }
        return config
      },
      (err) => {
        return err
      }
    )

    this.instance.interceptors.response.use(
      (res) => {
        setTimeout(() => {
          this.loading?.close() //可选链，
        }, 1000)
        return res
      },
      (err) => {
        return err
      }
    )
  }

  request<T = any>(config: HYRequestConfig): Promise<IResponseType> {
    return new Promise((resolve, reject) => {
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      } else {
        this.showLoading = DEAFULT_LOADING
      }
      //1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }
      this.instance
        .request<any, IResponseType>(config)
        .then((res) => {
          //1,单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          if (res.code == 200 || res.status == 200) {
            //3.讲结果返回出去
            resolve(res)
          } else if (res.code == 444) {
            //登录过期，
            localCache.clearCache()
            ElMessage({ message: "登录过期，请重新登录!", offset: 550 })
            location.reload()
          } else {
            //4.业务失败
            ElMessage({ message: res.msg ?? "请求失败!", offset: 150 })
            config.params.callback ? config.params.callback() : ""
          }
        })
        .catch((err) => {
          console.log("hyrequest-err:", err)
          this.showLoading = DEAFULT_LOADING
          reject(err)
          return err
        })
    })
  }

  post<T = any>(
    url: string,
    params: any,
    config?: HYRequestConfig
  ): Promise<IResponseType> {
    config = config ?? {}
    config.method = "POST"
    config.url = url
    config.params = params ?? {}
    return this.request(config)
  }

  get<T>(
    url: string,
    params: any,
    config?: HYRequestConfig
  ): Promise<IResponseType> {
    config = config ?? {}
    config.method = "GET"
    config.url = url
    config.params = params ?? {}
    return this.request(config)
  }

  delete<T>(url: string, params: any, config?: HYRequestConfig): Promise<T> {
    config = config ?? {}
    config.method = "DELETE"
    config.url = url
    config.params = params ?? {}
    return this.instance.request(config)
  }

  put<T>(url: string, params: any, config?: HYRequestConfig): Promise<T> {
    config = config ?? {}
    config.method = "PUT"
    config.url = url
    config.params = params ?? {}
    return this.instance.request(config)
  }

  patch<T>(url: string, params: any, config?: HYRequestConfig): Promise<T> {
    config = config ?? {}
    config.method = "PATCH"
    config.url = url
    config.params = params ?? {}
    return this.instance.request(config)
  }
}

export default HYRequest
