/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from "vue-router"
import { IBreadcrumb } from "@/base-ui/breadcumb"

let firstMenu: any = null

/**
 * 根据后台传过来的用户权限菜单，注册路由
 * @param userMenus
 */
export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = []

  //1,先去加载默认所有的routes
  const allRoutes: RouteRecordRaw[] = []
  const routeFiles = require.context("../router/main", true, /\.ts/)

  routeFiles.keys().forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const route = require("../router/main" + key.split(".")[1])
    allRoutes.push(route.default)
  })

  const analyzeMenuAndAddRouter = (menu: any) => {
    const route = allRoutes.find((route) => {
      return (
        menu.resType != 2 && //过滤操作
        route.path === menu.resUrl
      )
    })
    if (route) {
      routes.push(route)
      if (!firstMenu) {
        firstMenu = menu
      }
    }
  }

  //2.根据菜单获取需要添加的routes
  const _recurseGetRoute = (menus: any[]) => {
    for (const menu of menus) {
      if (menu.levels === 1) {
        if (menu.resType == 1) analyzeMenuAndAddRouter(menu)
      } else {
        analyzeMenuAndAddRouter(menu)
      }
      if (menu.children) _recurseGetRoute(menu.children)
    }
  }

  _recurseGetRoute(userMenus)

  return routes
}

export function pathMapBreadcrumbs(userMenus: any[], currentPath: string) {
  const breadcrumbs: IBreadcrumb[] = []
  pathMapToMenu(userMenus, currentPath, breadcrumbs)
  return breadcrumbs
}

export function pathMapToMenu(
  userMenus: any[],
  currentPath: string,
  breadcrumbs?: IBreadcrumb[]
): any {
  for (const menu of userMenus) {
    if (menu.levels === 1) {
      const findMenu = pathMapToMenu(menu.children ?? [], currentPath)
      if (findMenu) {
        breadcrumbs?.push({ name: menu.resName, path: menu.resUrl })
        breadcrumbs?.push({ name: findMenu.resName, path: findMenu.resUrl })
        return findMenu
      }
      return menu
    } else if (menu.levels === 2 && menu.resUrl === currentPath) {
      return menu
    }
  }
}

export function hasPermit(permitId: string): boolean {
  const userMenus = JSON.parse(localStorage.getItem("userMenus") ?? "{}")
  return hasPermitId(userMenus, permitId)
}

function hasPermitId(userMenus: any[], permitId: string): boolean {
  let flag = false
  for (const menu of userMenus) {
    if (menu.resPermission === permitId) {
      return true //找到就结束，否则子代继续查找
    } else {
      flag = hasPermitId(menu.children ?? [], permitId)
      if (flag) return true
    }
  }
  return flag
}

export { firstMenu }
