import { createRouter, createWebHashHistory } from "vue-router"
import type { RouteRecordRaw } from "vue-router"
import localCache from "@/utils/cache"
import { firstMenu, mapMenusToRoutes } from "@/utils/map-menus"
import store from "@/store"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/main"
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue")
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/views/main/main.vue")
    // children: [] -> 根据userMenus来决定
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/not-found/not-found.vue")
  }
]
const router = createRouter({
  routes,
  history: createWebHashHistory()
})

router.beforeEach((to) => {
  if (to.path !== "/login") {
    const token = localCache.getCache("token")
    if (!token) {
      return "/login"
    }

    if (to.path === "/main") {
      return firstMenu.resUrl
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userMenus = (store.state as any).login.userMenus
    const routes = mapMenusToRoutes(userMenus)

    routes.forEach((route) => {
      router.addRoute("main", route)
    })

    //注册标签管理路由
    let routeFiles = require.context(
      "@/router/main/wechatUser/userTag",
      false,
      /\.ts/
    )
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let route = require("@/router/main/wechatUser/userTag" +
      routeFiles.keys()[0].split(".")[1])
    router.addRoute("main", route.default)

    /************************** 注册非菜单路由 ****************************/
    //注册任务付款接单人路由
    routeFiles = require.context("@/router/main/billOrders", false, /\.ts/)
    route = require("@/router/main/billOrders" +
      routeFiles.keys()[0].split(".")[1])
    router.addRoute("main", route.default)

    //注册审批接单人路由
    routeFiles = require.context("@/router/main/orderUserRef", false, /\.ts/)
    route = require("@/router/main/orderUserRef" +
      routeFiles.keys()[0].split(".")[1])
    router.addRoute("main", route.default)

    //注册协议签署记录
    routeFiles = require.context(
      "@/router/main/agreementUserRef",
      false,
      /\.ts/
    )
    route = require("@/router/main/agreementUserRef" +
      routeFiles.keys()[0].split(".")[1])
    router.addRoute("main", route.default)
  }
})

export default router
