/* eslint-disable @typescript-eslint/no-explicit-any */
import hyRequest from "../../index"
import { IResponseType } from "@/service/types"

export function sendPost(url: string, queryParams: any) {
  return hyRequest.post<IResponseType>(url, queryParams)
}

export function getPageListData(url: string, queryParams: any) {
  return hyRequest.post<IResponseType>(url, queryParams)
}

export function deleteRowAction(url: string, id: string) {
  return hyRequest.delete<IResponseType>(url, id)
}

export function deleteBatchAction(url: string, ids: string) {
  return hyRequest.delete<IResponseType>(url, ids)
}

export function saveOrUpdateModel(url: string, model: any, config?: any) {
  return hyRequest.post<IResponseType>(url, model, config)
}
