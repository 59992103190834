/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, Store, useStore as useVuexStore } from "vuex"
import { IRootState, IStoreType } from "@/store/types"
import login from "@/store/login/login"
import system from "@/store/main/system/system"
import role from "@/store/main/role/role"
import user from "@/store/main/user/user"
import billMission from "@/store/main/billMission/billMission"
import orderUserRef from "@/store/main/orderUserRef/orderUserRef"
import { getPageListData } from "@/service/main/system/system"

const store = createStore<IRootState>({
  state: () => {
    return {
      name: "couderwhy",
      age: 18,
      height: 123,
      entireDepartment: [],
      entireRole: [],
      entireMenu: [],
      entireAgreements: [],
      entireUsers: [],
      entireUserTags: []
    }
  },
  mutations: {
    changeEntireRole(state, list: any[]) {
      state.entireRole = list
    },
    changeEntireAgreements(state, list: any[]) {
      state.entireAgreements = list
    },
    changeEntireMenu(state, list) {
      state.entireMenu = list
    },
    changeEntireUsers(state, list) {
      state.entireUsers = list
    },
    changeEntireUserTags(state, list) {
      state.entireUserTags = list
    }
  },
  getters: {},
  actions: {
    async getInitialDataAction({ commit, rootState }) {
      const roleReulst = await getPageListData("/role/list", {
        pageNum: 1,
        pageSize: 1000
      })

      let roleList = null
      if (roleReulst.data) {
        const { records } = roleReulst.data
        roleList = records
      }

      const menuResult = await getPageListData("/menu/allList", {})
      const menuList = menuResult.data

      const agreementResult = await getPageListData("/agreement/list", {
        pageNum: 1,
        pageSize: 1000
      })
      const { records: agreementList } = agreementResult.data as any

      const usersResult = await getPageListData("/user/list", {
        pageNum: 1,
        pageSize: 1000
      })
      const { records: entireUsers } = usersResult.data as any

      const userTagsResult = await getPageListData("/userTag/list", {
        pageNum: 1,
        pageSize: 1000
      })
      const { records: entireUserTags } = userTagsResult.data as any

      //保存数据
      const token = (rootState as any).login.token

      if (token) {
        //登录
        commit("changeEntireRole", roleList)
        commit("changeEntireAgreements", agreementList)
        commit("changeEntireUsers", entireUsers)
        commit("changeEntireUserTags", entireUserTags)
      }

      commit("changeEntireMenu", menuList)
    }
  },
  modules: {
    login,
    system,
    role,
    user,
    billMission,
    orderUserRef
  }
})

export function setupStore() {
  store.dispatch("login/loadLocalLogin")
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
