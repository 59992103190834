import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { registerApp } from "./global"
import "element-plus/dist/index.css"
import "normalize.css"
import "./assets/css/index.less"
import store, { setupStore } from "./store"

const app = createApp(App)

setupStore()

app.use(registerApp)
app.use(router)
app.use(store)

app.mount("#app")
