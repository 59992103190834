import {
  ElTag,
  ElBadge,
  ElTooltip,
  ElButton,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElLink,
  ElRate,
  ElTabPane,
  ElTabs,
  ElContainer,
  ElAside,
  ElHeader,
  ElMain,
  ElMenu,
  ElSubMenu,
  ElIcon,
  ElMenuItemGroup,
  ElMenuItem,
  ElCol,
  ElRow,
  ElRadioGroup,
  ElRadioButton,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElUpload,
  ElPopconfirm,
  ElInputNumber,
  ElCheckboxGroup,
  ElCheckboxButton,
  ElTreeSelect,
  ElRadio
} from "element-plus/lib"

import { App } from "vue"

import {
  Collection,
  ShoppingBag,
  Reading,
  OfficeBuilding,
  Finished,
  Notebook,
  Wallet,
  Money,
  User,
  Avatar,
  Cellphone,
  Calendar,
  Location,
  Document,
  Setting,
  Fold,
  Expand,
  CaretBottom,
  CaretTop,
  Edit,
  Delete,
  Search,
  Refresh,
  Plus,
  Shop,
  Tickets,
  Upload,
  Tools,
  List,
  Download,
  PriceTag,
  DataAnalysis,
  Back,
  InfoFilled,
  Ticket,
  View,
  CreditCard
} from "@element-plus/icons-vue"

const components = [
  Collection,
  ElTag,
  ElBadge,
  ShoppingBag,
  Reading,
  OfficeBuilding,
  Finished,
  Notebook,
  Wallet,
  CreditCard,
  Money,
  User,
  ElButton,
  ElForm,
  ElInput,
  ElRate,
  ElTabs,
  ElTabPane,
  Avatar,
  Cellphone,
  Calendar,
  ElTooltip,
  ElFormItem,
  ElCheckbox,
  ElLink,
  ElContainer,
  ElAside,
  ElHeader,
  ElMain,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElIcon,
  ElMenuItemGroup,
  ElCol,
  ElRow,
  ElRadioGroup,
  ElRadioButton,
  Location,
  Document,
  Setting,
  Fold,
  Expand,
  CaretBottom,
  CaretTop,
  Edit,
  Delete,
  Search,
  Refresh,
  Plus,
  Shop,
  Tickets,
  Upload,
  Tools,
  List,
  Download,
  PriceTag,
  DataAnalysis,
  Back,
  InfoFilled,
  Ticket,
  View,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElUpload,
  ElPopconfirm,
  ElInputNumber,
  ElCheckboxGroup,
  ElCheckboxButton,
  ElTreeSelect,
  ElRadio
]

export function registerElementPlus(app: App<Element>) {
  for (const component of components) {
    app.component(component.name, component)
  }
}
