import hyRequest from "../index"
import { IAccount, ILoginResult, IResult } from "./types"


enum LoginAPI {
  Login = "sys/login",
  userInfo = "sys/getUser",
  userMenus = "menu/getUserMenus",
  serviceScopes = "userTag/all"
}

export function accountLoginRequest(account: IAccount){
  console.log("单独请求的", account)
  return hyRequest.post<IResult<ILoginResult>>(LoginAPI.Login, account)
}

export function requestUserInfoByName(name: string){
  return hyRequest.post<IResult>(LoginAPI.userInfo, name, {showLoading: false} );
}

export function requestUserMenus(id: string){
  return hyRequest.post<IResult>(LoginAPI.userMenus, {userId: id}, {showLoading: false});
}

export function requestServiceScopes(){
  return hyRequest.post<IResult>(LoginAPI.serviceScopes, {}, {showLoading: false} );
}
