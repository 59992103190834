/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex"
import { IRoleState } from "@/store/main/role/types"
import { IRootState } from "@/store/types"
import { getResourceListAction } from "@/service/main/role/role"

const roleModule: Module<IRoleState, IRootState> = {
  namespaced: true,
  state() {
    return {
      resourceList: []
    }
  },
  getters: {
    resourceListData(state) {
      return state.resourceList
    }
  },
  mutations: {
    changeDefaultCheckeds(state, ids: any[]) {
      state.resourceList = ids
    }
  },
  actions: {
    async getResourceListAction({ commit }, payload: any) {
      const pageResult = await getResourceListAction(
        `role/getRoleMenuLeafs`,
        payload
      )
      commit("changeDefaultCheckeds", pageResult.data)
      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    }
  }
}

export default roleModule
