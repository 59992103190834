class LocalCache{
  setCache(key: string, value: any){
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  getCache(key: string){
    const value = window.localStorage.getItem(key) ;
    // console.log("loooo", value=='undefined')
    if(value){
      // console.log("loooo in", value)
      return JSON.parse(value);
    }
    // console.log("loooo out", '')
  }

  deleteCache(key: string){
    window.localStorage.removeItem(key);
  }

  clearCache(){
    window.localStorage.clear()
  }
}

export default new LocalCache()
