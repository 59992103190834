/* eslint-disable @typescript-eslint/no-explicit-any */
import HYRequest from "./request"
import { BASE_URL, TIME_OUT } from "@/service/request/config"
import localCache from "@/utils/cache"

export default new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      const token = localCache.getCache("token")
      if (token) {
        ;(config.headers as any).token = `${token}`
      }
      ;(config.headers as any)["X-Requested-With"] = "XMLHttpRequest" //标记为ajax请求

      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res.data
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})
