/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex"
import { ISystemState } from "@/store/main/system/types"
import { IRootState } from "@/store/types"
import {
  saveOrUpdateModel,
  deleteRowAction,
  deleteBatchAction,
  getPageListData
} from "@/service/main/system/system"

const systemModule: Module<ISystemState, IRootState> = {
  namespaced: true,
  state() {
    return {
      userList: [],
      userCount: 0,
      roleList: [],
      roleCount: 0,
      goodsList: [],
      goodsCount: 0,
      orderInfoList: [],
      orderInfoCount: 0,
      menuList: [],
      menuCount: 0,
      agreementList: [],
      agreementCount: 0,
      wechatUserList: [],
      wechatUserCount: 0,
      userTagList: [],
      userTagCount: 0,
      userTagId: "",
      bankJournalList: [],
      bankJournalCount: 0,
      billMissionList: [],
      billMissionCount: 0,
      billOrdersList: [],
      billOrdersCount: 0,
      orderUserRefList: [],
      orderUserRefCount: 0,
      agreementUserRefList: [],
      agreementUserRefCount: 0,
      entrustApplyList: [],
      entrustApplyCount: 0
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeUserList(state, userList: any[]) {
      state.userList = userList
    },
    changeUserCount(state, userCount: number) {
      state.userCount = userCount
    },
    changeRoleList(state, roleList: any[]) {
      state.roleList = roleList
    },
    changeRoleCount(state, roleCount: number) {
      state.roleCount = roleCount
    },
    changeGoodsList(state, goodsList: any[]) {
      state.goodsList = goodsList
    },
    changeGoodsCount(state, goodsCount: number) {
      state.goodsCount = goodsCount
    },
    changeOrderInfoList(state, orderInfoList: any[]) {
      state.orderInfoList = orderInfoList
    },
    changeOrderInfoCount(state, orderInfoCount: number) {
      state.orderInfoCount = orderInfoCount
    },
    changeMenuList(state, orderInfoList: any[]) {
      state.menuList = orderInfoList
    },
    changeMenuCount(state, orderInfoCount: number) {
      state.menuCount = orderInfoCount
    },
    changeAgreementList(state, agreementList: any[]) {
      state.agreementList = agreementList
    },
    changeAgreementCount(state, agreementCount: number) {
      state.agreementCount = agreementCount
    },
    changeWechatUserList(state, wechatUserList: any[]) {
      state.wechatUserList = wechatUserList
    },
    changeWechatUserCount(state, wechatUserCount: number) {
      state.wechatUserCount = wechatUserCount
    },
    changeUserTagList(state, userTagList: any[]) {
      state.userTagList = userTagList
    },
    changeUserTagCount(state, userTagCount: number) {
      state.userTagCount = userTagCount
    },
    changeUserTagId(state, userTagId: string) {
      state.userTagId = userTagId
    },
    changeBankJournalList(state, bankJournalList: any[]) {
      state.bankJournalList = bankJournalList
    },
    changeBankJournalCount(state, bankJournalCount: number) {
      state.bankJournalCount = bankJournalCount
    },
    changeBillMissionList(state, billMissionList: any[]) {
      state.billMissionList = billMissionList
    },
    changeBillMissionCount(state, billMissionCount: number) {
      state.billMissionCount = billMissionCount
    },
    changeBillOrdersList(state, billOrdersList: any[]) {
      state.billOrdersList = billOrdersList
    },
    changeBillOrdersCount(state, billOrdersCount: number) {
      state.billOrdersCount = billOrdersCount
    },
    changeOrderUserRefList(state, orderUserRefList: any[]) {
      state.orderUserRefList = orderUserRefList
    },
    changeOrderUserRefCount(state, orderUserRefCount: number) {
      state.orderUserRefCount = orderUserRefCount
    },
    changeAgreementUserRefList(state, agreementUserRefList: any[]) {
      state.agreementUserRefList = agreementUserRefList
    },
    changeAgreementUserRefCount(state, agreementUserRefCount: number) {
      state.agreementUserRefCount = agreementUserRefCount
    },
    changeEntrustApplyList(state, entrustApplyList: any[]) {
      state.entrustApplyList = entrustApplyList
    },
    changeEntrustApplyCount(state, entrustApplyCount: number) {
      state.entrustApplyCount = entrustApplyCount
    }
  },
  actions: {
    async getPageListAction({ commit }, payload: any) {
      const pageName = payload.pageName
      const pageUrl = `${pageName}/list`

      function capitalize(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1)
      }

      const pageResult = await getPageListData(pageUrl, payload.queryParams)
      const { records, total } = pageResult.data
      console.log("[FNO3]: 请求到的页面数据结果：", pageResult)
      commit(`change${capitalize(pageName)}Count`, total)
      commit(`change${capitalize(pageName)}List`, records)
    },
    async deleteRowAction(_, payload: any) {
      const pageName = payload.pageName

      const pageResult = await deleteRowAction(`${pageName}/del`, {
        ...payload
      })

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    },
    async deleteBatchAction(_, payload: any) {
      const pageName = payload.pageName

      const pageResult = await deleteBatchAction(`${pageName}/delBatch`, {
        ...payload
      })

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    },
    async saveOrUpdateModel(_, payload: any) {
      const pageName = payload.pageName
      const method = payload.method ?? "POST"

      const pageResult = await saveOrUpdateModel(
        `${pageName}/${method}`,
        payload.row,
        { data: payload.data }
      )

      if (pageResult.code == 200 && payload.callback) {
        payload.callback()
      }
    }
  }
}

export default systemModule
